import React from 'react';
import PropTypes from 'prop-types';
import './ModalToggleButton.css';


const ModalToggleButton = props => {
  const { handleBarsClick, isOpen } = props;
  return (
    <div className={'ModalToggleButton' + (isOpen ? ' active' : '')} onClick={handleBarsClick}>
      <span className="top"/>
      <span className="middle"/>
      <span className="bottom"/>
    </div>
  );
};

ModalToggleButton.propTypes = {
  handleBarsClick: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default ModalToggleButton;