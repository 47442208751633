import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import * as FeatureFlags from '../FeatureFlags';
import './MailingListForm.css';
const dsfBackendURL = 'https://us-central1-deep-sea-fire.cloudfunctions.net/dsfBackendApp';
const axios = require('axios');


const MailingListForm = (props) => {
  const [shouldShowForm, setShouldShowForm] = useState(FeatureFlags.mailingList);
  const [firstName, setFirstName] = useState('');
  const [firstNameValidationMessage, setFirstNameValidationMessage] = useState('*First name cannot be blank.');
  const [shouldShowFirstNameValidationMessage, setShouldShowFirstNameValidationMessage] = useState(false);
  const [email, setEmail] = useState('');
  const [emailValidationMessage, setEmailValidationMessage] = useState('*Email cannot be blank');
  const [shouldShowEmailValidationMessage, setShouldShowEmailValidationMessage] = useState(false);
  const [submitThankYouText, setSubmitThankYouText] = useState('');

  const handleEmailChange = event =>  {
    setEmail(event.target.value);
    if (!event.target.value) {
      setEmailValidationMessage('Email cannot be blank.')
    }
    if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(event.target.value)) {
      setEmailValidationMessage('*Invalid email address.');
    }
    else {
      setEmailValidationMessage('');
      setShouldShowEmailValidationMessage(false);
    }
  };

  const handleFirstNameChange = event => {
    setFirstName(event.target.value);
    if (!event.target.value) {
      setFirstNameValidationMessage('*First name cannot be blank.');
    } else {
      setFirstNameValidationMessage('');
      setShouldShowFirstNameValidationMessage(false);
    }
  };

  const resetInputs = () => {
    setEmail('');
    setFirstName('');
  };

  const inputsAreValid = () => {
    const isFirstNameValid = firstName;
    const isEmailValid = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email);
    if (!isFirstNameValid) {
      setShouldShowFirstNameValidationMessage(true);
    }
    if (!isEmailValid) {
      setShouldShowEmailValidationMessage(true);
    }

    return isFirstNameValid && isEmailValid;
  };

  const handleSubmit = event => {
    const { handleMailingListSubmit } = props;
    event.preventDefault();
    if (inputsAreValid()) {
      axios.post(`${dsfBackendURL}/create-fan`, {
        firstName: firstName,
        email: email,
      })
        .then(() => {})
        .catch (error => {
          console.error(error);
        });
      console.log('email is ', email);
      console.log('first name is ', firstName);
      resetInputs();
      setSubmitThankYouText('Please check your inbox in a few minutes!');
      setShouldShowForm(false);
      setTimeout(() => {
        handleMailingListSubmit();
      }, 1000);

    }
  };

  const handleDismiss = (event) => {
    const { handleMailingListDismiss } = props;
    setShouldShowForm(false);
    handleMailingListDismiss();
  };

  const { isVisible } = props;
  return (
    <div className={`MailingListBackground ${isVisible && 'visible' || ''}`}>
      <div className="submitPrompt">
        <div>{submitThankYouText}</div>
      </div>
      <div className={`MailingList ${shouldShowForm && 'visible' || ''}`}>
        <header className="mailingListHeader">
          {/*<FontAwesomeIcon className={'mailingListCloseButton'} icon={faTimes} onClick={handleDismiss} color="white"/>*/}
          MAILING LIST
        </header>
        <div className="mailingListContent">

          <div className="mailingListDescription">
            Keep in touch. I'll send you videos and tell you about my latest music and more.
          </div>
          <div className="mailingListForm">
            <div className="inputFieldContainer">
              <div>
                <div>First name</div>
                <input name="firstName" value={firstName} onChange={handleFirstNameChange} type="text"/>
                <div className={`validationMessage ${!shouldShowFirstNameValidationMessage && 'hidden'}`}>{firstNameValidationMessage}</div>
              </div>
              <div className="emailFieldContainer">
                <div>Email address</div>
                <input name="email" value={email} onChange={handleEmailChange} type="text"/>
                <div className={`validationMessage ${!shouldShowEmailValidationMessage && 'hidden'}`}>{emailValidationMessage}</div>
              </div>
            </div>
            <div className="submitButtonContainer">
              <button type="button" onClick={handleSubmit}>Submit</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

MailingListForm.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  handleMailingListSubmit: PropTypes.func.isRequired,
  handleMailingListDismiss: PropTypes.func.isRequired,
};

export default MailingListForm;