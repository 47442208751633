import React from 'react';

const NineONine = props => (
  <div>
    <iframe name="nine_o_nine" width="100%" height="166" scrolling="no" frameBorder="no" allow="autoplay"
            src="https://w.soundcloud.com/player/?url=https%3A%2F%2Fapi.soundcloud.com%2Ftracks%2F597433335&color=%235d9e4c&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"/>
  </div>
);

export default NineONine;
