import React from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";
import { withCookies } from 'react-cookie';
import Home from './Components/Home';
import Arrangements from './Components/Arrangements';
import Mixes from './Components/Mixes'
import Unreleased from './Components/Unreleased';
import FaultyDungeons from './Components/Releases/FaultyDungeons'
import Lemon from './Components/Releases/Lemon';
import TheForest from './Components/Releases/TheForest';
import NineONine from './Components/Releases/NineONine';
import TwoDoorCinemaClub from './Components/Releases/TwoDoorCinemaClub';

const AppRouter = (props) => (
  <Router>
    <div style={{height: '100%'}}>
      <Route path="/arrangements" exact component={Arrangements} />
      <Route path="/mixes" exact component={Mixes} />
      <Route path="/unreleased" exact component={Unreleased} />
      <Route path="/releases/faulty-dungeons" exact component={FaultyDungeons} />
      <Route path="/releases/lemon" exact component={Lemon} />
      <Route path="/releases/the-forest" exact component={TheForest} />
      <Route path="/releases/909" exact component={NineONine} />
      <Route path="/releases/two-door-cinema-club" exact component={TwoDoorCinemaClub} />
      <Route
        path="/"
        render={() => (<Home cookies={props.cookies}/>)}
      />
    </div>
  </Router>
);

export default withCookies(AppRouter);