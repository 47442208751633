import React, { Component } from 'react';
import PropTypes, { instanceOf } from 'prop-types';
import { Cookies } from 'react-cookie';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './Home.css';
import title from '../images/newTitle.png';
import azimPortrait from '../images/azimPort.jpg';
import Menu from './Menu';
import MailingListForm from './MailingListForm';
import LatestRelease from './LatestRelease';
import {faSpotify, faBandcamp, faSoundcloud} from "@fortawesome/free-brands-svg-icons/index";
import * as FeatureFlags from '../FeatureFlags';
const HAS_DISMISSED_MAILING_LIST_COOKIE = 'hasDismissedMailingList';
const HAS_SEEN_SIGNUP_FORM_COOKIE = 'hasSeenSignupForm';

class Home extends Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired,
 };

  constructor(props) {
    super(props);
    this.mainRef = React.createRef();
    this.mailingListRef = React.createRef();
    this.aboutRef = React.createRef();
    this.musicRef = React.createRef();
    this.instagramRef = React.createRef();

    this.scrollToMain = this.scrollToMain.bind(this);
    this.scrollToMailingList = this.scrollToMailingList.bind(this);
    this.scrollToAbout = this.scrollToAbout.bind(this);
    this.scrollToMusic = this.scrollToMusic.bind(this);
    this.scrollToInstagram = this.scrollToInstagram.bind(this);

    this.handleSignUpFormScroll = this.handleSignUpFormScroll.bind(this);
    this.disableSignupForm = this.disableSignupForm.bind(this);
    this.handleCloseButtonClick = this.handleCloseButtonClick.bind(this);
    this.handleMailingListSubmit = this.handleMailingListSubmit.bind(this);
    this.handleMailingListDismiss = this.handleMailingListDismiss.bind(this);

    this.signUpFormRef = React.createRef();
    this.state = {
      shouldDisplayMailingList: false,
      shouldShowSignupFormWithAnimation: false,
      // NOTE: set this to false once you set up a dropdown
      isSignupFormDisabled: true,
    }

  }
  componentDidMount() {
    const { cookies } = this.props;
    setTimeout(() => {
        this.setState({
            shouldShowSignupFormWithAnimation: !cookies.get(HAS_SEEN_SIGNUP_FORM_COOKIE),
        })
      }, 3000
    );
    setTimeout(() => {
      this.setState({
        shouldDisplayMailingList: FeatureFlags.mailingList //!cookies.get(HAS_DISMISSED_MAILING_LIST_COOKIE),
      });
    }, 3000);
    window.addEventListener('scroll', this.handleSignUpFormScroll);
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.handleSignUpFormScroll);
  }

  disableSignupForm(animated) {
    const { cookies } = this.props;
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    cookies.set(HAS_SEEN_SIGNUP_FORM_COOKIE, true, { path: '/', expires: tomorrow });
    if (animated) {
      this.setState({
        shouldShowSignupFormWithAnimation: false,
      });
    } else {
      this.setState({
        isSignupFormDisabled: true
      });
    }
  }

  handleSignUpFormScroll() {
    if (this.signUpFormRef.current.getBoundingClientRect().bottom <= 0) {
      // NOTE: uncomment the line below if you have a dropdown
      // this.disableSignupForm(false)
    }
  }

  handleMailingListSubmit() {
    this.setState({
      shouldDisplayMailingList: false
    });
  }

  handleMailingListDismiss() {
    const { cookies } = this.props;
    const threeDaysFromNow = new Date();
    threeDaysFromNow.setDate(threeDaysFromNow.getDate() + 3);
    cookies.set(HAS_DISMISSED_MAILING_LIST_COOKIE, true, { path: '/', expires: threeDaysFromNow });
    this.setState({
      shouldDisplayMailingList: false
    });
  }

  handleCloseButtonClick() {
      this.disableSignupForm(true)
  }

  scrollToMain(event) {
    console.log('scroll to main');
    event.preventDefault();
    window.scrollTo(0, this.mainRef.current.offsetTop);
  }

  scrollToMailingList(event) {
    console.log('scroll to mailing list');
    event.preventDefault();
    window.scrollTo(0, this.mailingListRef.current.offsetTop);
  }

  scrollToAbout(event) {
    console.log('scroll to about');
    event.preventDefault();
    window.scrollTo(0, this.aboutRef.current.offsetTop);
  }

  scrollToMusic(event) {
    console.log('scroll to music');
    event.preventDefault();
    window.scrollTo(0, this.musicRef.current.offsetTop);
  }

  scrollToInstagram(event) {
    console.log('scroll to contact');
    event.preventDefault();
    window.scrollTo(0, this.instagramRef.current.offsetTop);
  }


  render() {
    const { shouldDisplayMailingList } = this.state;
    const menuItems = [
      {
        itemName: 'Home',
        handleClick: this.scrollToMain
      },
      {
        itemName: 'Music',
        handleClick: this.scrollToMusic
      },
      {
        itemName: 'About',
        handleClick: this.scrollToAbout
      },
      {
        itemName: 'Instagram',
        handleClick: this.scrollToInstagram
      },
    ];

    if (shouldDisplayMailingList) {
      menuItems.splice(1, 0, {
        itemName: 'Mailing List',
        handleClick: this.scrollToMailingList
      });
    }

    return (
      <div>
        <Menu menuItems={menuItems}/>
        <div
          className={`signupContainer ${this.state.shouldShowSignupFormWithAnimation && 'signupFormOpen'} ${this.state.isSignupFormDisabled && ' signupFormDisabled'}`}
          ref={this.signUpFormRef}
        >
          <div className={"closeContainer"} onClick={this.handleCloseButtonClick}>↓</div>
        </div>
        <div id="home" className="Main" ref={this.mainRef}>
          <header className="Site-header">
            <img src={title} className={`bandTitle ${this.state.shouldDisplayMailingList ? '' : 'low'}`} alt="logo" />
          </header>
          <div id="mailing-list" className="mailingList" ref={this.mailingListRef}>
            <MailingListForm
              handleMailingListSubmit={this.handleMailingListSubmit}
              handleMailingListDismiss={this.handleMailingListDismiss}
              isVisible={this.state.shouldDisplayMailingList}
            />
          </div>
        </div>
        <div id="music" className="Music" ref={this.musicRef}>
          <header className="musicHeader">
            MUSIC
          </header>
          <div className="musicContent">
            {/*<LatestRelease/>*/}

            <div className="soundCloudPlayerContainer">
              <iframe
                width="100%"
                height="285"
                scrolling="no"
                frameBorder="no"
                allow="autoplay"
                src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/users/468065598&color=%2312ad85&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true">
              </iframe>
            </div>
            <div className="musicPlatformsContainer">
              <a href="https://open.spotify.com/artist/7pYtvyGa590DHRCXARYFOe" target="_blank">
                <FontAwesomeIcon icon={faSpotify} color="white" />
              </a>
              <a href="https://deepseafire.bandcamp.com/releases" target="_blank">
                <FontAwesomeIcon icon={faBandcamp} color="white" />
              </a>
              <a href="https://soundcloud.com/deepseafire" target="_blank">
                <FontAwesomeIcon icon={faSoundcloud} color="white"/>
              </a>
            </div>
          </div>
        </div>
        <div id="about" className="About" ref={this.aboutRef}>
          <header className="aboutHeader">
            ABOUT
          </header>
          <div className="aboutContent">
            <div className="aboutTextContainer">
              Deep Sea Fire is the solo-project of San Francisco-based artist and bedroom producer, Azim Pradhan.
              Azim often wakes up early to create a minute of new music before going to his day job.
              Many of these morning sketches develop into full-fledged songs that he later releases. Straddling the
              lines between electronic dance and alternative rock, Deep Sea Fire combines floor-moving bass-lines, ambient
              guitar riffs, and memorable melodies, with a wistful vocal tone. Deep Sea Fire launched
              in the spring of 2019 with the release of Lemon on April 1.
            </div>
            <div className="aboutPhotoContainer">
              <img src={azimPortrait} className="azimPortrait" alt="Portrait of Azim Pradhan" />
            </div>
          </div>
        </div>
        <div id="instagram" className="Instagram" ref={this.instagramRef}>
          <header className="instagramHeader">
            INSTAGRAM
          </header>
          <div className="instagramContent">
            <link href="https://assets.juicer.io/embed.css" media="all" rel="stylesheet" type="text/css"/>
            <ul className="juicer-feed" data-feed-id="deepseafire" data-style="slider"/>
          </div>
        </div>
        <div className="Footer">
          <div className="footerContent">
            <div className="SocialPlatforms">
              <a href="https://www.facebook.com/DeepSeaFire" target="_blank">
                FACEBOOK
              </a>
              <a href="https://www.instagram.com/deepseafire" target="_blank">
                INSTAGRAM
              </a>
              <a href="https://www.youtube.com/channel/UCYQOphXizyreIM6CJPn44lQ" target="_blank">
                YOUTUBE
              </a>
              <a href="https://open.spotify.com/artist/7pYtvyGa590DHRCXARYFOe" target="_blank">
                SPOTIFY
              </a>
              <a href="https://itunes.apple.com/us/artist/deep-sea-fire/1458297923" target="_blank">
                APPLE MUSIC
              </a>
              <a href="https://www.amazon.com/Lemon-feat-Ida-Deep-Fire/dp/B07Q4N983N" target="_blank">
                AMAZON
              </a>
              <a href="https://deepseafire.bandcamp.com" target="_blank">
                BANDCAMP
              </a>
            </div>
            <div className="footerLinks">
              <a href="mailto:info@deepseafire.com" target="_blank">
                Contact
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Home;
