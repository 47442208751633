import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './Menu.css';
import ModalToggleButton from './ModalToggleButton';
import Overlay from './Overlay';

class Menu extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
    this.handleBarsClick = this.handleBarsClick.bind(this);
    this.closeMenuCallback = this.closeMenuCallback.bind(this);
  }

  closeMenuCallback() {
    this.setState({
      isOpen: false
    });
  }

  handleBarsClick() {
    this.setState(prevState => ({
      isOpen: !prevState.isOpen
    }));
  }


  render() {
    const { menuItems } = this.props;
    return (
      <div className="Menu">
        <ModalToggleButton handleBarsClick={this.handleBarsClick} isOpen={this.state.isOpen}/>
        <Overlay isOpen={this.state.isOpen} menuItems={menuItems} closeMenuCallback={this.closeMenuCallback}/>
      </div>
    )
  }
}

Menu.propTypes = {
  menuItems: PropTypes.arrayOf(PropTypes.shape({
    itemName: PropTypes.string.isRequired,
    handleClick: PropTypes.func.isRequired,
  }))
};

export default Menu;