import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookSquare, faInstagram } from '@fortawesome/free-brands-svg-icons'
import './Overlay.css';


const Overlay = props => {
  const { isOpen, menuItems, closeMenuCallback } = props;
  return (
    <div className={'Overlay' + (isOpen ? ' open' : '')}>
      <nav className="overlayMenu">
        <ul>
          {menuItems.map((menuItem, index) => (
            <li key={index}>
              <a href={'#' + menuItem.itemName} onClick={(event) => {
                menuItem.handleClick(event);
                closeMenuCallback();
              }}>
                {menuItem.itemName}
              </a>
            </li>
          ))}
          <li className="overlaySocials">
            <a href="https://www.facebook.com/DeepSeaFire" target="_blank">
              <FontAwesomeIcon icon={faFacebookSquare} color="white" />
            </a>
            <a href="https://www.instagram.com/deepseafire" target="_blank">
              <FontAwesomeIcon icon={faInstagram} color="white" />
            </a>
          </li>
        </ul>
      </nav>
    </div>
  );
};

Overlay.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeMenuCallback: PropTypes.func.isRequired,
  menuItems:  PropTypes.arrayOf(PropTypes.shape({
    itemName: PropTypes.string.isRequired,
    handleClick: PropTypes.func.isRequired,
  }))
};

export default Overlay;
