import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Router from './Router';
import { CookiesProvider } from 'react-cookie';

// import registerServiceWorker from './registerServiceWorker';

ReactDOM.render(
  <CookiesProvider>
    <Router />
  </CookiesProvider>,

  document.getElementById('root'));
// registerServiceWorker();
