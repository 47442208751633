import React from 'react';

const Arrangements = props => (
    <div>
      <iframe width="100%" height="166" scrolling="no" frameBorder="no" allow="autoplay"
              src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/536051592%3Fsecret_token%3Ds-vPR92&color=%23cafcf4&auto_play=true&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"/>
      <iframe width="100%" height="166" scrolling="no" frameBorder="no" allow="autoplay"
              src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/536051298%3Fsecret_token%3Ds-Rw2xm&color=%23cafcf4&auto_play=true&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"/>
    </div>
);

export default Arrangements;